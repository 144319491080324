<template>
	<h1 class="logo text-center" v-bind="$attrs">
		<svg :width="width" :alt="title" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 36" fill="none">
			<path d="M141.645 30.2053C136.535 30.2053 132.377 26.7708 132.377 22.5491V11.6562C132.377 7.43453 136.535 4 141.645 4H158.731C163.842 4 168 7.43453 168 11.6562V22.5491C168 26.7708 163.842 30.2053 158.731 30.2053H141.645ZM140.057 10.2982C139.952 10.2982 139.866 10.3841 139.866 10.4895V23.7C139.866 23.8056 139.952 23.8914 140.057 23.8914H160.202C160.307 23.8914 160.393 23.8056 160.393 23.7V10.4895C160.393 10.3841 160.307 10.2982 160.202 10.2982H140.057Z" fill="black" />
			<path d="M87.4862 30.2055H66.1971C61.0862 30.2055 56.9283 26.7709 56.9283 22.5492V11.6526C56.9283 7.43086 61.0862 3.99633 66.1971 4.00056H87.3805L87.5346 4.00024C87.8588 4.00024 88.1217 4.26309 88.1217 4.58733L88.1218 4.61031V9.71258C88.1218 10.0368 87.8588 10.2997 87.5346 10.2997L87.4854 10.2994L64.6393 10.2997C64.5165 10.2997 64.4166 10.3997 64.4166 10.5225V23.6687C64.4166 23.7915 64.5165 23.8915 64.6393 23.8915L87.465 23.8917L87.5346 23.8919C87.8588 23.8919 88.1217 24.1547 88.1217 24.479L88.1219 24.4899L88.1192 29.6137L88.1199 29.6185C88.1199 29.9428 87.8588 30.2056 87.5346 30.2056L87.4862 30.2055Z" fill="black" />
			<path d="M125.593 30.2055H104.304C99.1934 30.2055 95.0356 26.7709 95.0356 22.5492V11.6526C95.0356 7.43086 99.1934 3.99633 104.304 4.00056H125.488L125.642 4.00024C125.966 4.00024 126.229 4.26309 126.229 4.58733L126.229 4.61031V9.71258C126.229 10.0368 125.966 10.2997 125.642 10.2997L125.593 10.2994L102.747 10.2997C102.624 10.2997 102.524 10.3997 102.524 10.5225V23.6687C102.524 23.7915 102.624 23.8915 102.747 23.8915L125.572 23.8917L125.642 23.8919C125.966 23.8919 126.229 24.1547 126.229 24.479L126.229 24.4899L126.226 29.6137L126.227 29.6185C126.227 29.9428 125.966 30.2056 125.642 30.2056L125.593 30.2055Z" fill="black" />
			<path d="M87.5962 20.0351H68.5519C68.3617 20.0351 68.2061 19.8795 68.2061 19.6892V14.5692C68.2061 14.3789 68.3617 14.2233 68.5519 14.2233H87.5962C87.7865 14.2233 87.9421 14.3789 87.9421 14.5692V19.6892C87.9421 19.8795 87.7865 20.0351 87.5962 20.0351Z" fill="#1554FF" />
			<path d="M32.7938 31.189C29.8591 31.189 27.1905 29.8544 25.8294 27.7059L25.7506 27.5741L12.4864 4.77789C12.3982 4.62062 12.4 4.43813 12.4891 4.28611C12.5923 4.10992 12.7954 4.00052 13.019 4.00052H20.018C20.2463 4.00052 20.4511 4.11307 20.5526 4.29428L32.5331 24.8004C32.5582 24.83 32.6332 24.8953 32.8049 24.8989C33.0012 24.8975 33.0562 24.8073 33.0827 24.764L46.0875 4.27443C46.1896 4.10689 46.3908 4.00052 46.6104 4.00052H53.7188C53.9467 4.00052 54.1516 4.11307 54.2534 4.29428C54.3415 4.45085 54.3379 4.63638 54.244 4.79061L39.8177 27.6153C38.4886 29.7987 35.8213 31.1692 32.8587 31.1888L32.7938 31.189Z" fill="black" />
			<path d="M6.96662 30.2058H0.587088C0.264192 30.2058 0 29.9417 0 29.6188V4.58739C0 4.26449 0.264192 4.00031 0.587088 4.00031H6.96662C7.28952 4.00031 7.55371 4.26449 7.55371 4.58739V29.6188C7.55371 29.9417 7.28952 30.2058 6.96662 30.2058Z" fill="black" />
		</svg>
	</h1>
</template>

<script>
export default {
	name: 'Logo',
	props: {
		width: {
			type: String,
			default: '170px'
		},
		title: {
			type: String,
			default: 'Iveco'
		}
	}
}
</script>
